import React, { useRef, useEffect } from 'react'

const OutsideClickComponent = (props:any) => {
  const wrapperRef: any = useRef(null)

  const handleClickOutside = (event:any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      props.onOutsideClick()
    }
  }

  useEffect(() => {
    typeof document !== 'undefined' && document.addEventListener('mousedown', handleClickOutside)
    return () => {
      typeof document !== 'undefined' && document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <div ref={wrapperRef}>
      {props.children}
    </div>
  )
}

export default OutsideClickComponent
