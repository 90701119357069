import styled from 'styled-components'
import ChevronRight from 'inter-frontend-svgs/lib/v2/navigation/chevron-right.svg'

export const WrapModal = styled.div`
  position: relative;
  z-index: 1000;
  .backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    transition: all .5s ease .25s;
    visibility: hidden;
  }

  .modal {
    background: #FFF;
    width: 100%;
    position: fixed;
    bottom: -100%;
    left: 0;
    min-height: 200px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    transition: all .5s ease-in;
    visibility: hidden;

    h2 {
      margin-top: ${8 * 3}px;
      margin-bottom: ${8 * 3}px;
      font-size: 24px;
      font-weight: 600;
      color: #194A69;
      text-align: center;
      &.color-gray{
        color:#4B4E5C;
        font-size:20px;
        line-height: 24px;
      }
    }

    h4{
      margin-top: ${8 * 3}px;
      margin-bottom: ${8 * 3}px;
      font-size:16px;
      font-weight: 600;
      color: #194A69;
      text-align: center;
    }

    p {
      font-weight: 300;
      font-size: 16px;
      margin-bottom: ${8*2}px;
      width: 100%;
      &.color-gray{
        font-size:14px;
        line-height:18px;
        color:#E6E7F0;
      }
    }

    .custom-options{
      padding:0px;
      margin:0px;
      list-style:none;
      padding-bottom: ${8 * 8}px;
      li{
        display:flex;
        position:relative;
        font-weight:300;
        align-items:center;
        margin-left:15px;
        width:calc(100% - 15px);
        color:#4B4E5C;
        min-height:50px;
        border-bottom: 1px solid #E6E7F0;
        &:last-child{
          border-bottom:0px;
        }

        .icon-chevronRight{
          position:absolute;
          height:100%;
          top:0px;
          right:0px;
          width:48px;
          display:flex;
          align-items:center;
          span.icon{
            -webkit-mask: url(${ChevronRight}) no-repeat center;
            mask: url(${ChevronRight}) no-repeat center;
            -webkit-mask-size: cover;
            mask-size: cover;
            background-color: #8C91A9;
            display:block;
            width:24px;
            margin:0 auto;
            height:16px;
          }
        }
      }
    }

    table {
      margin-bottom: ${8*3}px;

      thead {
        tr {
          background: #F5F6FA;
          font-size: 14px;

          th {
            padding: 8px;
          }
        }
      }

      tbody {
        tr {
          font-size: 12px;
          border-bottom: 1px solid #E6E7F0;
        }
      }
      
      thead,
      tbody {
        tr {
          td {
            width: 50%;
            padding: 8px;
          }
        }
      }
    }
  }

  &.active {
    position: relative;

    .backdrop {
      background: rgba(0, 0, 0, 0.5);
      transition: all .5s ease;
      visibility: visible;
    }

    .modal {
      bottom: 0px;
      visibility: visible;
      transition: all .5s ease-in;
    }
  }
`
