/**
 * Essa função é responsável por pesquisar se o Cookie com
 * o bearer do usuário está salvo no navegador
 * @param cname nome do cookie
 */

export const getCookie = (cname: string) => {
  if (typeof document !== 'undefined') {
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }
  return ''
}

/**
 * Função que cria o cookie com o nome parametrizado
 * @param cname nome do cookie
 */
export const setCookie = (cname: string) => { if (typeof document !== 'undefined') { document.cookie = cname } }
