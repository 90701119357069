/**
 * Retorna uma string do dia de hoje com o formato YYYY-MM-DD.
 */
const getTodayBR = (): string => new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getUTCDate()

/**
 * Retorna uma string do dia de hoje com o formato DD/MM/YYYY.
 */
const getTodayUS = (): string => new Date().getUTCDate()+'/'+(new Date().getMonth()+1)+'/'+new Date().getFullYear()

/**
 * Retorna uma string com o formato YYYY-MM-DD.
 * @param date array de string
 */
const formatUniversalDate = (date: any): string => date[2]+'-'+date[1]+'-'+date[0]

/**
 * Converte uma data do tipo string no formato YYYY-MM-DD
 * e retorna uma data do tipo Date.
 * @param date data no formato YYYY-MM-DD
 * @return Date
 */
const convertToDate = (date: string): Date => {
  const formatedDate = formatUniversalDate(date)
  return new Date(formatedDate)
}

/**
 * Converte uma string de data no formato YYYY-MM-DD
 * e retorna uma data do tipo string no formato DD/MM/YYYY.
 * @param date data no formato 'YYYY-MM-DD'
 * @return string DD/MM/YYYY
 */
const converteUniversalDateToBRDate = (date: string): string => {
  const newDate: Date = convertToDate(date)
  return newDate.getUTCDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear()
}

/**
 * Retorna o dia da semana em português,
 * a partir de uma data no formato DD/MM/YYYY.
 * @param date data no formato DD/MM/YYYY
 */
const getWeekDate = (date: any): string => {
  const day = convertToDate(date.split('/')).getDay()
  switch (day) {
    case 0:
      return 'seg'
    case 1:
      return 'ter'
    case 2:
      return 'qua'
    case 3:
      return 'qui'
    case 4:
      return 'sex'
    case 5:
      return 'ab'
    case 6:
    default:
      return 'dom'
  }
}

/**
 * Retorna o mês em português,
 * a partir de uma data no formato DD/MM/YYYY.
 * @param date data no formato DD/MM/YYYY
 */
const getMonth = (date: any): string => {
  const month = convertToDate(date.split('/')).getMonth()
  switch (month) {
    case 0:
      return 'jan'
    case 1:
      return 'fev'
    case 2:
      return 'mar'
    case 3:
      return 'abr'
    case 4:
      return 'mai'
    case 5:
      return 'jun'
    case 6:
      return 'jul'
    case 7:
      return 'ago'
    case 8:
      return 'set'
    case 9:
      return 'out'
    case 10:
      return 'nov'
    default:
      return 'dez'
  }
}

/**
 * Verifica se as strings de data parametrizadas são iguais,
 * e retorna as strings 'hoje' se for hoje,
 * ou o dia da semana se for outro dia que não hoje.
 * @param today data no formato DD/MM/YYYY
 * @param currentDate data no formato DD/MM/YYYY
 */
const isToday = (today: string, currentDate: string): string => {
  return today === currentDate ? 'hoje' : getWeekDate(currentDate)
}

export {
  getTodayBR,
  getTodayUS,
  formatUniversalDate,
  convertToDate,
  getWeekDate,
  getMonth,
  isToday,
}
