import React, { ReactNode, useEffect, useState } from 'react'
import { WrapModal } from './style'

type TModal = {
  children?: ReactNode;
  // 'Normal', 'BottomSheet'
  style?: string;
  activeCloseModal?: boolean;
  triggerModal?: boolean;
  callbackClose?: any;
}

const Modal = ({ children, style, activeCloseModal, triggerModal, callbackClose }: TModal) => {
  const [ isOpenModal, setIsOpenModal ]: [ any, any ] = useState(false)

  const close = () => {
    if (callbackClose) callbackClose()
    if (typeof document !== 'undefined') {
      const body:any = document.body
      body.classList.remove('noScroll')
      setIsOpenModal(false)
    }
  }

  const open = () => {
    setIsOpenModal(true)
    if (typeof document !== 'undefined') {
      const body:any = document.body
      body.classList.add('noScroll')
    }
  }

  useEffect(() => {
    if (triggerModal) open()
    else close()
  }, [ triggerModal ])

  return (
    <WrapModal className={`${(isOpenModal) ? 'active' : ''}`}>
      <div className='backdrop' onClick={() => (activeCloseModal) ? close() : ''} />
      <div className={`modal ${style}`}>
        {children}
      </div>
    </WrapModal>
  )
}

export default Modal
