import styled from 'styled-components'

type TDatePickerStyle = {
  size: string;
}

const size = '49px;'
const sizeSmall = '40px'
const colorHover = 'linear-gradient(45deg, #194A69 0%, #072534 100%);'

export const DatePickerStyle = styled.div<TDatePickerStyle>`
  position: relative;
  .react-datepicker {
    &-wrapper {
      float: left;
      margin-left: 15px;
    }

    &__input-container {
      input {
        background: #F5F6FA;
        border-radius: 4px;
        outline: none;
        border: none;
        min-height: 40px;
        padding: 10px 8px;
        font-size: 14px;
        width: 117px;
      }
    }

    &__month-container {
      float: left;
      margin: 0px 16px;
    }

    &__navigation {
      z-index: 100;
      position: absolute;
      top: 10px;
      border: none;
      color: transparent;

      &--previous {
        left:11px;
        outline: 0px;
        width: 20px;
        height: 20px;
        &:after{
          position: absolute;
          top:5px;
          left:0;
          content: '';
          border: solid #8C91A9;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
        }
      }

      &--next {
        right:11px;
        outline: 0px;
        width: 20px;
        height: 20px;
        &:after{
          position: absolute;
          top:5px;
          right:0;
          content: '';
          border: solid #8C91A9;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
      }
    }

    &__month {
      .react-datepicker__week{
        &:last-child {
          border-bottom: 0px;
          justify-content: flex-start;
        }
      }
    }
  }

  .react-datepicker-popper {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 24px 8px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    
    .react-datepicker {
      font-family: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 0.8rem;
      color: #000;
      border-radius: 0.3rem;
      display: inline-block;
      position: relative;

      /** Trocar o estilo do header do mês */
      &__header {
        text-align: center;
        padding-top: 8px;
        position: relative;
      }

      /** Trocar o estilo do título do mês */
      &__current-month {
        font-size: 20px;
        margin-top: 0;
        color: #194A69;
        font-weight: bold;
        text-transform: capitalize;
      }

      /** Trocar o estilo dos dias da semana */
      &__day-names,
      &__week {
        white-space: nowrap;
      }
      
      &__day-name {
        color: #4B4E5C;
        display: inline-block;
        width: ${(props: TDatePickerStyle) => props.size === 'default' ? size : sizeSmall};
        line-height: ${(props: TDatePickerStyle) => props.size === 'default' ? size : sizeSmall};
        font-size: ${(props: TDatePickerStyle) => props.size === 'default' ? '16px' : '14px'};
        font-weight: 300;
        text-align: center;
        text-transform:uppercase;
      }

      /** Trocar o estilo da lista de dias */
      &__week {
        border-bottom: 0.5px solid #D1D4E3;
        padding-bottom: 2px;
        margin-top: 3px;
        display: flex;
        justify-content: flex-end;

        .react-datepicker {
          &__day{
            display: inline-block;
            width: ${(props: TDatePickerStyle) => props.size === 'default' ? size : sizeSmall};
            font-weight: 300;
            line-height: ${(props: TDatePickerStyle) => props.size === 'default' ? size : sizeSmall};
            font-size: ${(props: TDatePickerStyle) => props.size === 'default' ? '16px' : '12px'};
            text-align: center;
            position: relative;
            cursor: pointer;

            &:hover,
            &--selected {
              color: #FFF;
              
              &.react-datepicker__day--today{
                color: #FFF;
                &::before {
                  content: '';
                }
              }
              &::after {
                content: '';
                height: ${(props: TDatePickerStyle) => props.size === 'default' ? '37px' : '24px'};
                width: ${(props: TDatePickerStyle) => props.size === 'default' ? '37px' : '24px'};
                position: absolute;
                z-index: -1;
                left: ${(props: TDatePickerStyle) => props.size === 'default' ? '6px' : '8px'};
                top: ${(props: TDatePickerStyle) => props.size === 'default' ? '6px' : '8px'};
                background: ${colorHover};
                border-radius: 50%;
              }
            }

            &--today {
              color: #EB7000;

              &:hover {
                &::before {
                  content: '';
                }
              }

              &::before {
                content: 'Hoje';
                position: absolute;
                font-size: ${(props: TDatePickerStyle) => props.size === 'default' ? '12px' : '10px'};
                top: ${(props: TDatePickerStyle) => props.size === 'default' ? '-18px' : '-15px'};
                left: ${(props: TDatePickerStyle) => props.size === 'default' ? '15px' : '11px'};
              }
            }

            &--outside {
              &-month {
                opacity: 0;
                display: none;
              }
            }

            &--disabled {
              color: #E6E7F0;
              cursor: not-allowed;

              &:hover {
                color: #E6E7F0;
                &::after {
                  display: none;
                }
              }
            }
            
            &--range-start,
            &--selecting-range-end,
            &--range-end:not(.react-datepicker__day--in-selecting-range) {
              color: #FFF;

              &::after {
                content: '';
                width: 37px;
                position: absolute;
                z-index: -1;
                left: 6px;
                top: 6px;
                height: 37px;
                background: ${colorHover};
                border-radius: 50%;
              }
            }

            &--in-selecting-range {
              &::after {
                display: none;
              }
            }

            &--selecting-range-end,
            &--selecting-range-start {
              &::after {
                display: block;
              }
            }

            &--in-selecting-range:not(.react-datepicker__day--selecting-range-end):not(.react-datepicker__day--selecting-range-start),
            &--in-range {
              &::before {
                content: '';
                width: 100%;
                height: 30px;
                background: #F5F6FA;
                position: absolute;
                top: 10px;
                z-index: -22;
                left: 0px;
              }
            }

            &--selecting-range-start,
            &--selecting-range-start:hover,
            &--range-start:not(.react-datepicker__day--in-selecting-range) {
              &::before {
                content: '';
                width: 50%;
                height: 30px;
                background: #F5F6FA;
                position: absolute;
                top: 10px;
                z-index: -22;
                right: 0px;
                left: initial;
              }
            }

            &--selecting-range-end,
            &--selecting-range-end:hover,
            &--range-end:not(.react-datepicker__day--in-selecting-range) {
              &::before {
                content: '';
                width: 50%;
                height: 30px;
                background: #F5F6FA;
                position: absolute;
                top: 10px;
                z-index: -22;
                left: 0px;
              }
            }
          }
        }
      }
    }
  }
`
